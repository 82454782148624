import React from "react";
import './Header.css'


const Header: React.FC = () => {
    return (
        <header className='App-header'>
            <h3>DARTERS.RU</h3>
        </header>
    )
}
export default Header;